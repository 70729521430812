import React, { useRef, Fragment } from "react"
import { connectInfiniteHits } from "react-instantsearch-dom"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import { Link } from "gatsby"
import FavoriteCardIcon from "@components/Favorite/FavoriteCardIcon"

function Hits({ hits, hasMore, refineNext }) {
  const moreRef = useRef(null)
  const handleMoreRef = () => {
    moreRef.current.click()
  }
  return (
    <Fragment>
      <div class="flex flex-wrap -mx-1 lg:-mx-4 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-8 lg:grid-cols-3">
        {hits.map((hit, index) => {
          return (
            <div class="card shadow-lg bg-base-100 w-full" key={index}>
              <div class="card-body p-5">
                <div className="absolute bg-transparent right-4 top-4 z-10">
                  <div className="flex items-start justify-end">
                    <div className="h-7 flex items-center">
                      <div className="cursor-pointer rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                        <FavoriteCardIcon
                          name={hit.data.title}
                          id={hit.data.title}
                          price={hit.data.price}
                          slug={hit.data.slug}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Link to={`/${hit.data.slug}`} key={hit.data.title}>
                  <div className="logo relative flex-1 text-center">
                    <Img
                      alt={hit.data.title}
                      image={
                        hit.data.logo
                          ? hit.data.logo.localFiles[0].childImageSharp
                              .gatsbyImageData
                          : null
                      }
                    />
                  </div>
                </Link>
                <Link to={`/${hit.data.slug}`} key={hit.data.title}>
                  <div className="flex-shrink-0 flex justify-between text-base-content">
                    <h2 className="card-title mb-0 mt-3">{hit.data.title}</h2>
                    <p className="mt-4">${hit.data.price} USD</p>
                  </div>
                </Link>
              </div>
            </div>
          )
        })}
      </div>
      {hasMore && (
        <div
          class="w-full text-center p-4"
          onClick={handleMoreRef}
          ref={moreRef}
        >
          <button
            class="btn btn-primary mt-6"
            disabled={!hasMore}
            onClick={refineNext}
          >
            Show more results
          </button>
        </div>
      )}
    </Fragment>
  )
}

const CustomHits = connectInfiniteHits(Hits)

export default CustomHits
