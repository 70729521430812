import React, { useEffect } from "react"
import CustomHits from "./CustomHits"
import AllCards from "@components/AllCards"
import { connectStateResults } from "react-instantsearch-dom"

const StateResults = ({ searchResults, data, postEdges }) => {
  const hasResults = searchResults && searchResults.nbHits !== 0
  const nbHits = searchResults && searchResults.nbHits
  const showHits = searchResults && searchResults.query

  return (
    <div>
      {/* <div hidden={!hasResults}>There are {nbHits} results</div> */}
      <div hidden={!hasResults}>
        {nbHits < data ? (
          <CustomHits />
        ) : (
          <div class="container mx-auto sm:px-4 2xl:max-w-7xl max-w-6xl">
            <AllCards postEdges={postEdges} />
          </div>
        )}
      </div>
    </div>
  )
}

const CustomStateResults = connectStateResults(StateResults)

export default CustomStateResults
