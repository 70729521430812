import React, { useState, useEffect, Fragment } from "react"
import { Link } from "gatsby"
import FavoriteCardIcon from "@components/Favorite/FavoriteCardIcon"
import { GatsbyImage as Img } from "gatsby-plugin-image"

const PostCards = props => {
  let postCard = []
  props.postEdges.forEach(postEdge => {
    postCard.push({
      slug: postEdge.node.data.slug,
      id: postEdge.node.id,
      tags: postEdge.node.data.tags,
      price: postEdge.node.data.price,
      category: postEdge.node.data.category,
      image: postEdge.node.data.logo
        ? postEdge.node.data.logo.localFiles[0].childImageSharp.gatsbyImageData
        : null,
      title: postEdge.node.data.title,
    })
  })

  const sorted = postCard.sort(GetSortOrder("price"))

  function GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return -1
      } else if (a[prop] < b[prop]) {
        return 1
      }
      return 0
    }
  }

  // State for the list
  const [list, setList] = useState([...sorted.slice(0, 15)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(postCard.length > 15)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < postCard.length
      const nextResults = isMore
        ? postCard.slice(currentLength, currentLength + 15)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < postCard.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  return (
    <Fragment>
      <div class="flex flex-wrap -mx-1 lg:-mx-4 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-8 lg:grid-cols-3 2xl:grid-cols-3 lg:gap-x-8">
        {list.map(post => (
          <div class="card shadow-xl bg-base-100 w-full">
            <div class="card-body p-5">
              <div className="absolute bg-transparent right-4 top-4 z-10">
                <div className="flex items-start justify-end">
                  <div className="h-7 flex items-center">
                    <div className="cursor-pointer rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                      <FavoriteCardIcon
                        name={post.title}
                        id={post.title}
                        price={post.price}
                        slug={post.slug}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Link to={`/${post.slug}`} key={post.title}>
                <div className="logo relative flex-1 text-center">
                  <Img alt={post.title} image={post.image} />
                </div>
              </Link>
              <Link to={`/${post.slug}`} key={post.title}>
                <div className="flex-shrink-0 flex justify-between text-base-content">
                  <h2 className="card-title mb-0 mt-3">{post.title}</h2>
                  <p className="mt-4">${post.price} USD</p>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
      {hasMore ? (
        <div class="w-full text-center">
          <button onClick={handleLoadMore} class="btn btn-primary mt-12">
            Load More
          </button>
        </div>
      ) : (
        <div class="w-full text-center">
          <p class="btn btn-ghost mt-6">No more results</p>
        </div>
      )}
    </Fragment>
  )
}

export default PostCards
