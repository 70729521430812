import React from "react"
import { connectCurrentRefinements } from "react-instantsearch-dom"
import { createClassNames } from "./core/utils"

const cx = createClassNames("CurrentRefinements")

const CurrentRefinements = ({ items, refine }) => (
  <div class="sm:flex justify-center">
    {items.map(item => (
      <div
        key={item.label}
        class={`${
          (item.label.includes("data.length") &&
            "length flex justify-center items-center mx-2") ||
          (item.label.includes("data.industry") && "industry") ||
          (item.label.includes("data.price") &&
            "price flex justify-center items-center mx-2")
        }`}
      >
        {item.items ? (
          <div class="flex justify-center">
            <div>
              {item.label
                .replace("data.Sections:", "")
                .replace("data.tld:", "")
                .replace("data.industry:", "")}
            </div>
            <div>
              {item.items.map(nested => (
                <div
                  class="sm:inline-flex btn btn-xs sm:btn-sm m-2 dark:bg-base-100"
                  key={nested.label}
                >
                  <div>{nested.label}</div>
                  <button
                    className={cx("delete ml-2")}
                    onClick={() => refine(nested.value)}
                  >
                    ✕
                  </button>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            {parseInt(item.label.replace(/[^0-9]+/g, "")) > 2000 ? (
              <div class="flex btn btn-xs sm:btn-sm dark:bg-base-100">
                {item.label
                  .replace("<= data.price <=", "Price:")
                  .replace("data.price <=", "Price: ")
                  .replace("<= data.price", "Price: ")
                  .replace(/[0-9]/g, "") +
                  "$" +
                  item.label
                    .replace("<= data.price <= ", "to $")
                    .replace("<= data.price", "or higher")
                    .replace("data.price <= ", "")
                    .replace("<= data.price ", "$")}
                <button
                  className={cx("delete ml-2")}
                  onClick={() => refine(item.value)}
                >
                  ✕
                </button>
              </div>
            ) : (
              <div class="flex btn btn-xs sm:btn-sm my-2 sm:my-0 dark:bg-base-100">
                {item.label
                  .replace("<= data.length <=", "Length:")
                  .replace("<= data.length", "Length:")
                  .replace(/[0-9]/g, "") +
                  item.label
                    .replace("<= data.length <=", "to ")
                    .replace("<= data.length", "or higher")}
                <button
                  className={cx("delete ml-2")}
                  onClick={() => refine(item.value)}
                >
                  ✕
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    ))}
  </div>
)

const CustomCurrentRefinements = connectCurrentRefinements(CurrentRefinements)

export default CustomCurrentRefinements
