import React, { Fragment, useEffect, useRef } from "react"
import PriceRange from "./PriceRange"
import Industry from "./Industry"
import Tld from "./Tld"
import SortBy from "./SortBy"
import CustomSearchSide from "./CustomSearchSide"
import LengthSlider from "./LengthSlider"

export default ({
  userFilterForTld,
  urlFilterForTld,
  userFilterForIndustry,
  urlFilterForIndustry,
}) => {
  const inputRef = useRef()

  useEffect(() => {
    inputRef.current.click()
  }, [])

  return (
    <Fragment>
      <LengthSlider min={1} attribute="data.length" />
      <PriceRange attribute="data.price" />
      <div class="collapse overflow-visible collapse-arrow p-0 mb-6 -mt-4">
        <input type="checkbox" ref={inputRef} />
        <div class="collapse-title text-xl font-medium">Industry</div>
        <div class="collapse-content">
          <Industry
            attribute="data.industry"
            label="false"
            showMore="true"
            userFilter={userFilterForIndustry}
            defaultRefinement={
              urlFilterForIndustry === null ? [] : urlFilterForIndustry
            }
            translations={{
              showMore(expanded) {
                return expanded ? (
                  <p class="text-base font-semibold">Show less</p>
                ) : (
                  <p class="text-base font-semibold">Show more</p>
                )
              },
              noResults: "No results",
              submitTitle: "Submit your search query.",
              resetTitle: "Clear your search query.",
              placeholder: "Search here...",
            }}
            limit={12}
          />
        </div>
      </div>
      <div class="collapse overflow-visible collapse-arrow p-0 mb-6">
        <Tld
          attribute="data.tld"
          showMore="true"
          userFilter={userFilterForTld}
          defaultRefinement={urlFilterForTld === null ? [] : urlFilterForTld}
          translations={{
            showMore(expanded) {
              return expanded ? (
                <div class="badge badge-primary">Show less</div>
              ) : (
                <div class="badge badge-primary">Show more</div>
              )
            },
            noResults: "No results",
            submitTitle: "Submit your search query.",
            resetTitle: "Clear your search query.",
            placeholder: "Search here...",
          }}
          limit={8}
        />
      </div>
      <p class="text-lg my-4 font-medium leading-normal text-base-content">
        Search:
      </p>
      <SortBy
        className="sortby-mobile"
        defaultRefinement="brandshore"
        items={[
          {
            value: "brandshore",
            label: "Contains",
          },
          {
            value: "starts_with",
            label: "Starts with",
          },
          {
            value: "ends_with",
            label: "Ends with",
          },
        ]}
      />
      <CustomSearchSide />
    </Fragment>
  )
}
