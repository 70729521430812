import React, { useEffect } from "react"
import { connectSearchBox, PoweredBy } from "react-instantsearch-dom"
import { HiSearch } from "react-icons/hi"
import "./poweredby.css"

const SearchBox = ({ currentRefinement, refine, label, location }) => {
  // const inputRef = useRef(null)
  // useEffect(() => {
  //   inputRef.current.click()
  // })

  const handleLocation = () => refine(location)

  useEffect(() => {
    handleLocation()
  }, [])

  return (
    <div class="ais-SearchBox">
      <form
        class="flex items-start w-full sm:mt-4 2xl:mt-12 lg:mx-auto lg:justify-center lg:w-2/3 relative"
        noValidate
        role="search"
        onSubmit={e => e.preventDefault()}
      >
        <div class="absolute top-5 bottom-0 left-3 z-10">
          <HiSearch className="dark:text-gray-400 text-3xl cursor-pointer" />
        </div>
        <input
          type="search"
          class="shadow-lg flex-grow w-full text-base-content px-12 py-5 mb-4 text-base text-black transition duration-650 ease-in-out transform rounded-lg bg-blueGray-200 focus:outline-none focus:border-purple-500 sm:mb-0 focus:bg-white focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 md:text-lg md:px-12 dark:bg-base-100"
          // ref={inputRef}
          value={currentRefinement}
          onLoad={handleLocation}
          onChange={event => refine(event.currentTarget.value)}
          // onClick={event => userSearch(event, refine)}
          placeholder="Search domain"
          aria-label={`Search ${label}`}
        />
        <PoweredBy
          translations={{
            searchBy: "Search by",
          }}
        />
      </form>
    </div>
  )
}

const CustomSearchBox = connectSearchBox(SearchBox)

export default CustomSearchBox
