import React from "react"
import { connectSortBy } from "react-instantsearch-dom"

const SortBy = ({ items, refine }) => (
  <div class="tabs tabs-boxed p-0 mt-4 justify-center">
    {items.map(item => (
      <button
        key={item.value}
        value=""
        onClick={event => {
          event.preventDefault()
          refine(item.value)
        }}
        class={`${
          item.isRefined
            ? "tab px-2 2xl:px-3 tab-sm tab-active"
            : "tab px-2 2xl:px-3 tab-sm "
        }`}
      >
        {item.label}
      </button>
    ))}
  </div>
)

export default connectSortBy(SortBy)
