import React, { useState, useEffect } from "react"
import { connectRange } from "react-instantsearch-dom"

const PriceSlider = ({ currentRefinement, min, max, precision, refine }) => (
  <form>
    <div class="form-control mb-6">
      <p class="text-lg font-medium leading-normal text-base-content">
        Price range:
      </p>
      <div class="flex flex-wrap">
        <div class="sm:my-4 w-1/2">
          <input
            type="number"
            min={min}
            max={max}
            step={1 / Math.pow(10, precision)}
            value={currentRefinement.min || ""}
            onChange={event =>
              refine({
                ...currentRefinement,
                min: event.currentTarget.value,
              })
            }
            class="input mr-1.5 bg-base-200"
          />
        </div>
        <div class="sm:my-4 w-1/2">
          <input
            type="number"
            min={min}
            max={max}
            step={1 / Math.pow(10, precision)}
            value={currentRefinement.max || ""}
            onChange={event =>
              refine({
                ...currentRefinement,
                max: event.currentTarget.value,
              })
            }
            class="input ml-1.5 bg-base-200"
          />
        </div>
      </div>
    </div>
  </form>
)

export default connectRange(PriceSlider)
