import React, { useState } from "react"
import { connectRefinementList } from "react-instantsearch-dom"

const Tld = ({ items, showMore, translations, refine, limit, userFilter }) => {
  const [extended, setExtended] = useState(false)
  return (
    <>
      <p class="text-lg mb-4 font-medium leading-normal text-base-content">
        Tld:
      </p>
      <ul>
        {items.map(
          (item, i) =>
            (i < limit || extended) && (
              <li key={item.label} class="flex mb-4">
                <input
                  checked={item.isRefined ? "checked" : ""}
                  class="checkbox mr-2"
                  type="checkbox"
                  onClick={() => {
                    userFilter(item.value, refine)
                  }}
                />
                <p>{item.label}</p>
              </li>
            )
        )}
      </ul>
      {/* {showMore && (
        <button
          onClick={() => {
            setExtended(!extended)
          }}
          className="ais-RefinementList-showMore"
        >
          {translations["showMore"](extended)}
        </button>
      )} */}
    </>
  )
}

export default connectRefinementList(Tld)
