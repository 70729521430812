import React, { useEffect } from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import "./poweredby.css"

const SearchSide = ({ currentRefinement, refine, label, location }) => {
  const handleLocation = () => refine(location)

  useEffect(() => {
    handleLocation()
  }, [])

  return (
    <div class="ais-SearchBox mb-8">
      <form
        class="form-control mt-6"
        noValidate
        role="search"
        onSubmit={e => e.preventDefault()}
      >
        <input
          type="search"
          class="input bg-base-200"
          value={currentRefinement}
          onLoad={handleLocation}
          onChange={event => refine(event.currentTarget.value)}
          // onClick={event => userSearch(event, refine)}
          placeholder="Search domain"
          aria-label={`Search ${label}`}
        />
      </form>
    </div>
  )
}

const CustomSearchBox = connectSearchBox(SearchSide)

export default CustomSearchBox
