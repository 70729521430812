import React, { useState, useEffect } from "react"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch } from "react-instantsearch-dom"
import CustomSearchBox from "./CustomSearchBox"
import CustomStateResults from "./CustomStateResults"
import CustomCurrentRefinements from "./CustomCurrentRefinements"
import Filter from "./Filter"
import { StaticQuery, graphql } from "gatsby"
import { globalHistory } from "@reach/router"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const updateQuery = (query, tld, industry) => {
  window.history.replaceState(
    null,
    null,
    tld || industry
      ? `?query=${encodeURIComponent(query)}&filter=${encodeURIComponent(tld)
          .replace(/%20/g, "+")
          .replace(/null/g, "")}${
          industry
            ? `&industry=${encodeURIComponent(industry).replace(/%20/g, "+")}`
            : ""
        }`
      : query
      ? `?query=${encodeURIComponent(query)}`
      : "?"
  )
}

const userSearch = (event, refine) => {
  let currentQuery = getSearch()
  refine(event.currentTarget.value)
  updateQuery(
    event.currentTarget.value,
    currentQuery.filter,
    currentQuery.industry
  )
}

const userFilterTld = (tld, refine) => {
  let currentQuery = getSearch()
  refine(tld)
  updateQuery(
    currentQuery.query === null ? "" : currentQuery.query,
    tld,
    currentQuery.industry
  )
}

const userFilterIndustry = (industry, refine) => {
  let currentQuery = getSearch()
  refine(industry)
  updateQuery(
    currentQuery.query === null ? "" : currentQuery.query,
    currentQuery.filter,
    industry
  )
}

const getSearch = () => {
  let currentSearch = new URLSearchParams(window.location.search)
  let parameters = {
    query: currentSearch.get("query"),
    filter: currentSearch.get("filter"),
    industry: currentSearch.get("industry"),
  }
  return parameters
}

const resetSearch = (refine, items) => {
  refine(items)
  updateQuery("")
}

export default props => {
  const [toggle, setToggle] = useState(false)

  function handleToggle() {
    document.body.classList.add("modal-open")
    setToggle(true)
  }

  function handleToggleClose() {
    setTimeout(() => {
      setToggle(false)
    }, 500)
  }

  const isBrowser = typeof window !== "undefined"
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0)

  useEffect(() => {
    if (!isBrowser) return false
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  // URL
  let urlQuery = globalHistory.location.search
    ? decodeURIComponent(
        globalHistory.location.search
          .replace("?query=", "")
          .replace(/&filter.*/, "")
      )
    : ""
  let urlFilterForTld =
    globalHistory.location.search.search("&industry=") > 1
      ? globalHistory.location.search.search("&filter=") < 1
        ? decodeURIComponent(
            globalHistory.location.search
              .replace(/.*&filter=/, "")
              .replace(/\+/g, " ")
          ).split(",")
        : []
      : globalHistory.location.search.search("&filter=") > 1
      ? decodeURIComponent(
          globalHistory.location.search
            .replace(/.*&filter=/, "")
            .replace(/\+/g, " ")
        ).split(",")
      : []
  let urlFilterForIndustry =
    globalHistory.location.search.search("&industry=") > 1
      ? decodeURIComponent(
          globalHistory.location.search
            .replace(/.*&industry=/, "")
            .replace(/\+/g, " ")
        ).split(",")
      : []

  return (
    <InstantSearch searchClient={searchClient} indexName="brandshore">
      {/* <SortBy
        className="sort-by"
        defaultRefinement="brandshore"
        items={[
          { value: "brandshore", label: "All Domains" },
          // { value: "textual_attribute", label: "Alphabetically" },
          {
            value: "price_asc",
            label: "Price asc.",
          },
          // {
          //   value: "instant_search_price_desc",
          //   label: "Price desc.",
          // },
        ]}
      /> */}

      <div className="outer">
        <div className="inner">
          <div>
            <div className="relative bg-zircon dark:bg-base-200 overflow-hidden drawer drawer-end">
              <input
                id="my-drawer-4"
                type="checkbox"
                class="drawer-toggle"
              ></input>
              <div
                className="relative pt-6 pb-6 sm:pb-16"
                data-todo-x-data="Components.popover({ open: false, focus: true })"
                data-todo-x-init="init()"
                data-todo-at-keydown-escape="onEscape"
                data-todo-at-close-popover-group-window="onClosePopoverGroup"
              >
                <main className="mt-8 mx-auto max-w-7xl 2xl:max-w-7xl px-4">
                  <div className="text-center">
                    <h1 className="text-3xl tracking-tight font-extrabold text-base-content sm:text-3xl md:text-5xl">
                      <span className="block">Search Domain Names</span>
                    </h1>
                    <div
                      class="flex items-start w-full mt-12 lg:mx-auto lg:justify-center lg:w-2/3"
                      bis_skin_checked="1"
                    >
                      <div
                        class="relative w-full text-left lg:w-full xl:w-full md:w-full sm:mb-8"
                        bis_skin_checked="1"
                      >
                        <CustomSearchBox location={props.location} />
                      </div>
                    </div>
                    <CustomCurrentRefinements />
                  </div>
                </main>
              </div>
            </div>
          </div>
          <div class="bg-zircon dark:bg-base-200 text-base-content w-full pb-12">
            <div class="2xl:max-w-7xl max-w-6xl flex flex-col lg:flex-row m-auto">
              <div class="w-full lg:w-72 2xl:w-80 relative mr-4 2xl:mr-0">
                <div class="py-3 w-full flex items-center justify-center lg:hidden cursor-pointer font-bold">
                  Change Filters
                  <label
                    class="btn btn-square btn-ghost ml-4"
                    for="my-drawer-5"
                    onClick={handleToggle}
                  >
                    <svg
                      className="h-6 w-6"
                      data-todo-x-description="Heroicon name: outline/globe-alt"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 512 512"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill="currentColor"
                        d="M496 384H160v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h80v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h336c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160h-80v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h336v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h80c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160H288V48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16C7.2 64 0 71.2 0 80v32c0 8.8 7.2 16 16 16h208v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h208c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16z"
                      ></path>
                    </svg>
                  </label>
                </div>
                {width < 640 ? (
                  <div
                    style={{
                      zIndex: toggle ? "100" : "-10",
                    }}
                    class={`fixed top-0 inset-x-0 h-screen drawer drawer-end`}
                  >
                    <input
                      id="my-drawer-5"
                      type="checkbox"
                      class="drawer-toggle"
                    ></input>
                    <div class="flex flex-col items-center justify-center drawer-content">
                      <div className="container h-screen relative overflow-hidden drawer drawer-end">
                        <input
                          id="my-drawer-5"
                          type="checkbox"
                          class="drawer-toggle"
                        ></input>
                        <div
                          className="relative pt-6 pb-16 sm:pb-24"
                          data-todo-x-data="Components.popover({ open: false, focus: true })"
                          data-todo-x-init="init()"
                          data-todo-at-keydown-escape="onEscape"
                          data-todo-at-close-popover-group-window="onClosePopoverGroup"
                        >
                          {/* Main */}
                        </div>
                      </div>
                    </div>
                    <div class="drawer-side">
                      <label
                        onClick={handleToggleClose}
                        for="my-drawer-5"
                        class="drawer-overlay"
                      ></label>
                      <div class="bg-base-100 p-4 overflow-y-auto bg-base-100 text-base-content">
                        <Filter
                          userFilterForIndustry={userFilterIndustry}
                          userFilterForTld={userFilterTld}
                          urlFilterForTld={urlFilterForTld}
                          urlFilterForIndustry={urlFilterForIndustry}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div class="hidden card bg-base-100 shadow-xl sm:p-4 left-0 lg:px-auto w-full lg:w-auto mt-10 lg:mt-0 top-0 pt-6 pb-24 lg:flex flex-col">
                    <div class="p-2 2xl:p-4">
                      <Filter
                        toggle={toggle}
                        userFilterForIndustry={userFilterIndustry}
                        userFilterForTld={userFilterTld}
                        urlFilterForTld={urlFilterForTld}
                        urlFilterForIndustry={urlFilterForIndustry}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div class="flex-1 lg:pl-6 2xl:pl-10 pb-6 px-6 lg:px-0">
                <div>
                  {/* <h1 class="text-base font-bold">Results</h1> */}
                  <StaticQuery
                    query={graphql`
                      query {
                        all: allAirtable(
                          sort: { fields: id, order: ASC }
                          filter: {
                            data: {
                              status: { ne: "sold" }
                              noimage: { eq: "true" }
                            }
                          }
                        ) {
                          edges {
                            node {
                              data {
                                logo {
                                  localFiles {
                                    id
                                    childImageSharp {
                                      gatsbyImageData(
                                        width: 300
                                        placeholder: NONE
                                      )
                                    }
                                  }
                                }
                                slug
                                title
                                price
                              }
                            }
                          }
                        }
                      }
                    `}
                    render={data => (
                      <div class="w-full">
                        <CustomStateResults
                          data={data.all.edges.length}
                          postEdges={data.all.edges}
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InstantSearch>
  )
}
