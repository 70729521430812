import React from "react"
import Search from "@components/Search/Search"
import { ThemeProvider } from "../components/themeContext"
import Header from "@components/Header"
import Footer from "@components/Footer"
import Layout from "../components/Layout"
import HeaderPromotion from "../components/HeaderPromotion"
import "../css/tailwind.css"

const searchIndices = [{ name: `demo` }]

export default ({ location }) => {
  if (!location.state) {
    return (
      <ThemeProvider>
        <Layout>
          <HeaderPromotion />
          <Header />
          <Search indices={searchIndices} />
          <Footer />
        </Layout>
      </ThemeProvider>
    )
  } else {
    return (
      <ThemeProvider>
        <Layout>
          <HeaderPromotion />
          <Header />
          <Search
            indices={searchIndices}
            location={location.state.fromSearch}
          />
          <Footer />
        </Layout>
      </ThemeProvider>
    )
  }
}
